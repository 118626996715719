export default {
  data() {
    return {
      screenWidth: 0,
      bLoading: false,
      subcatalog: "",
      warehouse: "",
      numberBoxes: "",
      unitPrice: "",
      unitMeasure: "",
      productAvailableTime: "",
      lotNumber: "",
      transportationTemp: "",
      storageTemp: "",
      itemsSubcategories: [],
      itemsUnitMeasure: [],
      texts: "",
      articleNameEn: "",
      articleNameSp: "",
      articleDescriptionEn: "",
      articleDescriptionSp: "",
      menu: false,
      images: [],
      allImages: [],
      imagesDeletes: [],
      iTotalImages: 0,
    };
  },
  beforeMount() {
    this.texts = FILE.articleTexts[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.close();

  },
  methods: {
    handleResize() {
      if (window.innerWidth >= 960) {
        this.screenWidth = 40;
      } else {
        this.screenWidth = 100;
      }
    },
    close() {
      this.$store.commit("setDialogEditArticle", {
        active: false,
        arr: [],
      });
      this.subcatalog = "";
      this.warehouse = "";
      this.numberBoxes = "";
      this.unitPrice = "";
      this.unitMeasure = "";
      this.productAvailableTime = "";
      this.lotNumber = "";
      this.transportationTemp = "";
      this.storageTemp = "";
      this.itemsSubcategories = [];
      this.itemsUnitMeasure = [];
      this.articleNameEn = "";
      this.articleNameSp = "";
      this.articleDescriptionEn = "";
      this.articleDescriptionSp = "";
      // this.$refs.clearUpload.clear();
      this.imagesDeletes = [];
      this.images = [];
    },
    setImages(e) {
      this.images = e;
      this.iTotalImages = this.images.length + this.allImages.length;
    },
    editArticle() {
      if (this.iTotalImages < 1) {
        this.mixError("Es necesario agregar minimo 1 imagen.");
        return;
      } else {
        const bImages = this.images.length > 0;

        this.bLoading = true;

        const params = {
          sSubcategoryId: this.subcatalog,
          sVendorWarehouseId: this.warehouse,
          dMinAmountAllowed: this.numberBoxes,
          dUnitPrice: this.unitPrice,
          sMeasurementUnitId: this.unitMeasure,
          tProductAvailableTime: this.productAvailableTime,
          sLotId: this.lotNumber,
          dTransportationTemperature: this.transportationTemp,
          dStorageTemperature: this.storageTemp,
          aImagesKey: this.imagesDeletes,
          aTranslations: [
            {
              sLang: "sp",
              sName: this.articleNameSp,
              sDescription: this.articleDescriptionSp,
            },
            {
              sLang: "en",
              sName: this.articleNameEn,
              sDescription: this.articleDescriptionEn,
            },
          ],
        };

        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        };

        DB.put(
          `${URI}/api/v1/${this.selectLanguage}/products/${this.$store.state.arrEditArticle.sProductId}`,
          params,
          config
        )
          .then((response) => {
            if (bImages) {
              this.sendImage(response.data.results.sProductId);
            } else {
              this.bLoading = false;
              this.close();
              this.mixSuccess(response.data.message);
              this.$store.commit("refresher", true);
            }
          })
          .catch((error) => {
            this.bLoading = false;
            this.mixError(error.response.data.message, error.response.status);
          });
      }
    },
    subcategory() {
      //post credentials and get access token from laravel backend
      DB.get(`${URI}/api/v1/${this.selectLanguage}/subcategories`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          iPageNumber: 1,
        },
      })
        .then((response) => {
          this.itemsSubcategories = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    articles() {
      //post credentials and get access token from laravel backend
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/products/${this.$store.state.arrEditArticle.sProductId}`,
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
          params: {},
        }
      )
        .then((response) => {
          this.allImages = response.data.results.aImages;
          this.iTotalImages = response.data.results.aImages.length;
          this.subcatalog = response.data.results.sSubcategoryId;
          this.warehouse = response.data.results.sVendorWarehouseId;
          this.numberBoxes = response.data.results.dMinAmountAllowed;
          this.unitPrice = response.data.results.dUnitPrice;
          this.unitMeasure = response.data.results.sMeasurementUnitId;
          this.productAvailableTime =
            response.data.results.tProductAvailableTime;
          this.lotNumber = response.data.results.sLotId;
          this.transportationTemp =
            response.data.results.dTransportationTemperature;
          this.storageTemp = response.data.results.dStorageTemperature;
          this.articleNameEn = response.data.results.aTranslations.find(
            (res) => {
              return res.sLang == "en";
            }
          ).sName;
          this.articleNameSp = response.data.results.aTranslations.find(
            (res) => {
              return res.sLang == "sp";
            }
          ).sName;
          this.articleDescriptionEn = response.data.results.aTranslations.find(
            (res) => {
              return res.sLang == "en";
            }
          ).sName;
          this.articleDescriptionSp = response.data.results.aTranslations.find(
            (res) => {
              return res.sLang == "sp";
            }
          ).sName;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    measureUnits() {
      //post credentials and get access token from laravel backend
      DB.get(`${URI}/api/v1/${this.selectLanguage}/measurement_units`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          iPageNumber: 1,
        },
      })
        .then((response) => {
          this.itemsUnitMeasure = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    remove(i, item) {
      if (item.sImageKey) {
        this.imagesDeletes.push(item.sImageKey);
      }
      this.allImages.splice(i, 1);
      this.$emit("sendImages", this.allImages);
      this.iTotalImages = this.images.length + this.allImages.length;
    },
    sendImage(sProductId) {
      var form = new FormData();
      form.append("image", this.images[0]);
      form.append("image", this.images[1]);
      form.append("image", this.images[2]);
      form.append("image", this.images[3]);
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/products/${sProductId}/storage/`,
        form,
        config
      )
        .then((response) => {
          this.bLoading = false;
          this.close();
          this.mixSuccess(response.data.message);
          this.$store.commit("refresher", true);
          this.images = [];
        })
        .catch((err) => {
          this.bLoading = false;
          this.mixError(err.response.data.message);
        });
    },
  },
  computed: {
    dialogEditArticle() {
      return this.$store.state.dialogEditArticle;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
    validateForm() {
      return (
        this.subcatalog !== "" &&
        this.warehouse !== "" &&
        this.numberBoxes !== "" &&
        this.unitPrice !== "" &&
        this.unitMeasure !== "" &&
        this.productAvailableTime !== "" &&
        this.productAvailableTime !== "" &&
        this.lotNumber !== "" &&
        this.transportationTemp !== "" &&
        this.storageTemp !== "" &&
        this.articleNameEn !== "" &&
        this.articleNameSp !== "" &&
        this.articleDescriptionEn !== "" &&
        this.articleDescriptionSp !== "" &&
        this.allImages !== "" &&
        this.subcatalog !== null &&
        this.warehouse !== null &&
        this.numberBoxes !== null &&
        this.unitPrice !== null &&
        this.unitMeasure !== null &&
        this.productAvailableTime !== null &&
        this.productAvailableTime !== null &&
        this.lotNumber !== null &&
        this.transportationTemp !== null &&
        this.storageTemp !== null &&
        this.articleNameEn !== null &&
        this.articleNameSp !== null &&
        this.articleDescriptionEn !== null &&
        this.articleDescriptionSp !== null &&
        this.allImages !== null
      );
    },
  },
  watch: {
    dialogEditArticle: function() {
      if (this.dialogEditArticle) {
        this.texts = FILE.articleTexts[this.selectLanguage];
        this.articles();
        this.subcategory();
        this.measureUnits();
      }
    },
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.articleTexts[this.selectLanguage];
      }
    },
  },
};
